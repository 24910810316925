@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');

body {
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    text-decoration: none;
    list-style: none;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;

}

.nav-title {
    font-family: 'Montserrat', sans-serif;
    z-index: 1;
}

.hero-text {
    font-family: 'Poppins', sans-serif;
}
  
.screen2_container{
    width: 17em;
    height: 80vh;
    position: relative;
    overflow: hidden;
    color: rgb(54, 53, 53);
    border: 15px solid rgb(54, 53, 53);
    border-top: 30px solid rgb(54, 53, 53);
    border-bottom: 30px solid rgb(54, 53, 53);
    border-radius: 60px;
}

.screen2_menu_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    background-color: #151528;
    color: white;
    height: 100px;
}

.screen2_menu_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 100px;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.screen2_menu_hamburger{
    width: 60px;
    height: 10px;
    background-color: white;
    border-radius: 5px;
    z-index: 5;
    transition: all .5s ease;
}

.screen2_menu_hamburger::before,
.screen2_menu_hamburger::after{
    content: '';
    position: absolute;
    width: 60px;
    height: 10px;
    background-color: white;
    border-radius: 5px;
    transition: all .5s ease;
    z-index: 5;
}

.nav--links li {
    list-style: none;
    color: white;
    text-decoration: none;
    letter-spacing: 3px;
    /* font-weight: bold; */
    font-size: 20px;
    margin-left: 20px;
    cursor: pointer;
}

.nav--links a {
    text-decoration: none;
    letter-spacing: 3px;
    /* font-weight: bold; */
    font-size: 20px;
}


.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: white;
}
  
.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
  
.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
@media screen and (min-width: 990px) {
    .screen2_menu_container{
        padding-left: 5%;
        padding-right: 5%;
    }
    .screen2_menu_btn {
        display: none;
    }
    .nav--links {
        display: flex;
        justify-content: space-around;
        width: 30%;
    }
}

@media screen and (min-width: 990px) and (max-width: 1400px) { 
    .nav--links {
        width: 40%;
    }

    .screen2_menu_container{
        height: 60px;
    }

    .nav--links li {
        font-size: 12px;
    }

}
@media screen and (max-width: 990px) {
    .nav--links {
        display: none;
    }
}

.screen2_menu_hamburger::before{
transform: translateY(-18px);
}

.screen2_menu_hamburger::after{
transform: translateY(18px);
}

.screen2_menu_btn input:checked ~.screen2_menu_hamburger::before{
transform: rotate(45deg) translate(35px, -35px);
}

.screen2_menu_btn input:checked ~.screen2_menu_hamburger::after{
transform: rotate(-45deg) translate(35px, 35px);
}

.screen2_menu_btn input:checked ~.screen2_menu_hamburger{
transform: translateX(-50px);
background: transparent;
}

input[type=checkbox]:checked+.nav-title {
    z-index: -1;

}

.screen2_menu_items{
    position: absolute;
    top: -550px;
    background:  #151528;
    height: 25.65%;
    width: 100%;
    left: 0;
    transition: all .5s ease-out;
    padding-top: 60px;
}
.screen2_menu_items li{    
    padding: 24px 0;
    text-align: center;
    transition: all .2s ease-out;
    color: white;
    font-size: 2rem;
    font-weight: 600;
    z-index: 12;
    padding-left: 20px;
    background-color: #151528;
    border-bottom: 0.5px solid #fff;
    z-index: 51;
}

.screen2_menu_items li:hover{
    letter-spacing: 2px;
    opacity: .6;
}

.screen2_menu_btn input:checked ~.screen2_menu_items{
    top:0;
}

.screen2_menu_btn input{
display: none;
}

/* CONTACT BUTTON */

.btn {
    cursor: pointer;
    /* z-index: -1; */
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-size: 35px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1em 2.5em;
    display: inline-block;
    border-radius: 6em;
    transition: all .2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: white;
    background-color: #151528;
}
   
.btn:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
   
.btn:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
   
.btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s;
}
   
.btn::after {
    background-color: #151528;
}
   
.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translate(-100%);
    transition: all 1s;
}

.hidden-r {
    opacity: 0;
    filter: blur(5px);
    transform: translate(100%);
    transition: all 1s;
}


.show {
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    transition: all 1s;
}

@media screen and (min-width: 320px) {
    .screen2_menu_container{
        height: 140px;
    }
}

@media screen and (min-width: 990px) and (max-width: 1400px) { 
    .btn {
        font-size: 16px;
    }
}

@media screen and (min-width: 1024px) {
    .btn {
        font-size:25px;
    }

    .screen2_menu_container{
        height: 100px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1536px) {
    .screen2_menu_container{
        height: 80px;
    }

    .btn {
        font-size:20px;
    }
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

.sticky + .content {
    padding-top: 60px;
}

.z-minus {
    z-index: -1;
}

/* ANIMATIONS */

@keyframes slideIn {
    from {
        visibility: visible;
        transform: translateX(50px);
    }

    to {
        transform: translateY(0);
        visibility: visible;
    }
}

@keyframes slideInLong {
    from {
        transform: translateX(100px);
        visibility: visible;

    }

    to {
        transform: translateY(0);
        visibility: visible;
    }
    
}


@keyframes anim-lineUp2 {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }
    20% {
        opacity: 0;
    }
    70% {
        opacity: 1;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
        visibility: visible;
    }
    20% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
        visibility: visible;    
    }
}

@keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}

.lineUp {
    visibility: hidden;  
    animation: 2s anim-lineUp ease-out 0.3s;
    animation-fill-mode: forwards;
}


.lineUp2 {
    visibility: hidden;  
    animation: 2s anim-lineUp ease-out 0.6s;
    animation-fill-mode: forwards;
}

.lineUp3 {
    visibility: hidden;  
    animation: 2s anim-lineUp ease-out 0.9s;
    animation-fill-mode: forwards;
}

.lineUp4 {
    visibility: hidden;  
    animation: 2s anim-lineUp ease-out 1.2s;
    animation-fill-mode: forwards;
}

.lineUp5 {
    visibility: hidden;  
    animation: 2s anim-lineUp ease-out 1.5s;
    animation-fill-mode: forwards;
}

.slide-in {
    visibility: hidden;  
    animation: 0.5s slideIn ease-out, 0.5s fadein ease-in;
    animation-fill-mode: forwards;
}

.slide-in-long {
    visibility: hidden;  
    animation: 0.8s slideInLong ease-out, 0.8s fadein ease-in;
    animation-fill-mode: forwards;
}

.slide-in-long-2 {
    visibility: hidden;  
    animation: 0.8s slideInLong ease-out 1.2s, 0.8s fadein ease-in 1.2s;
    animation-fill-mode: forwards;
}

.slide-in-long-3 {
    visibility: hidden;  
    animation: 0.8s slideInLong ease-out 0.3s, 0.8s fadein ease-in 0.3s;
    animation-fill-mode: forwards;
}
/* /////////// */

.reveal-text {
    visibility: hidden;
}

.line {
    position: relative;
    overflow: hidden;
    display: flex;
}

/* FOOTER */
.footer{
    background:#151528;
    padding:30px 0px;
    font-family: 'Play', sans-serif;
    text-align:center;
    }
        
    .footer .row{
    width:100%;
    margin:1% 0%;
    /* padding:0.6% 0%; */
    color:white;
    /* font-size:0.8em; */
    }
    
        .footer .row a{
        text-decoration:none;
        /* color:oran; */
        transition:0.5s;
        font-size:2em;
        }
    
    .footer .row a:hover{
    color:#fff;
    }
    
    .footer .row ul{
    width:100%;
    }
    
    .footer .row ul li{
    display:inline-block;
    margin:0px 20px;
    }
    
    .footer .row a i{
    font-size:2em;
    margin:0% 1%;
    color: white;
    }
    
    @media (min-width: 320px) and (max-width:768px){
        .footer .row a{
            text-decoration:none;
            color:white;
            transition:0.5s;
            font-size:3em;
            padding-top: 12px;
            }
    .footer{
    text-align:left;
    padding:5%;
    }
    .footer .row ul li{
    display:block;
    margin:10px 0px;
    text-align:left;
    
    .footer .row{
        margin-top: 5px;
    }

    }
    .footer .row a i{
    margin:0% 3%;
    }
    }